import React, { useState, useRef, useEffect } from 'react';
import QRCodeStyling from "qr-code-styling";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import logo from "../logo.svg"

const qrCode = new QRCodeStyling({
    width: 512,
    height: 512,
    image: logo,
    dotsOptions: {
        color: "#000000",
        type: "rounded"
    },
    type: "canvas",
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 20
    }
});

function Home() {
    const [isValidLink, setIsValidLink] = useState(false);
    const [link, setLink] = useState('');
    const [newLink, setNewLink] = useState('');
    const ref = useRef(null);

    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    useEffect(() => {
        const observer = new MutationObserver((mutationList, observer) => {
            for (let mutation of mutationList) {
                if (mutation.type === 'childList') {
                    const canvas = document.querySelector('canvas');

                    if (canvas) {
                        canvas.style.width = '100%';
                        observer.disconnect();
                    }
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, [isValidLink]);

    useEffect(() => {
        if (isValidLink === true) {
            qrCode.update({
                data: newLink
            });
        }
        else {
            qrCode.update({
                data: ''
            });
        }
    }, [link, isValidLink]);

    const handleChange = (event) => {
        event.preventDefault();
        const URL = event.target.value;
        const ppguCore = "https://petition.president.gov.ua/petition/";
        const pknuguaCore = "https://petition.kmu.gov.ua/petitions/";

        setLink(URL);
        if (URL.includes(ppguCore)) {
            setIsValidLink(true);
            setNewLink(`https://dolya.net.ua/petition?adr=ppgu&id=${URL.slice(ppguCore.length)}`);
        }
        else if (URL.includes(pknuguaCore)) {
            setIsValidLink(true);
            setNewLink(`https://dolya.net.ua/petition?adr=pknugua&id=${URL.slice(pknuguaCore.length)}`);
        }
        else {
            setIsValidLink(false);
            setNewLink('');
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(newLink);
    }
     
    const handleShare = async () => {
        qrCode.getRawData('png').then(function (blob) {
            const file = new File([blob], 'qr.png', { type: 'image/png' });

            navigator.share({
                files: [file]
            }).then(() => {
                console.log('Збс');
            }).catch(error => {
                console.log(error);
            })
        });

    }

    const handleDownload = async () => {
        qrCode.download({
            name: 'qr',
            extension: 'png'
        })
    }

    return (
        <div className="flex flex-col flex-auto container mx-auto">
            <div className="flex-auto flex flex-col gap-4">
                <input className="w-full bg-white rounded-xl p-2 text-black focus:outline-none" type="text" placeholder="Вставте посилання" value={link} onChange={handleChange} />
                <div className={`${newLink === '' ? 'scale-0' : 'scale-100'} bg-white rounded-xl p-2 transition-transform`}>
                    <span className="text-black">{newLink} </span>
                    <button type="button" onClick={handleCopy}>
                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            </div>
            <div className={`${newLink === '' ? 'scale-0' : 'scale-100'} flex flex-col gap-4 transition-transform`}>
                <div ref={ref} className="aspect-square max-w-[512px] mx-auto" />
                <div className="flex gap-4">
                    <button className="w-1/2 bg-black rounded-xl p-3.5 text-2xl text-white" type="button" onClick={handleDownload}>Зберегти</button>
                    <button className="w-1/2 bg-black rounded-xl p-3.5 text-2xl text-white" type="button" onClick={handleShare}>Поширити</button>
                </div>
            </div>
        </div>
    );
}

export default Home;