import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
function RedirectToApp() {
    let query = new URLSearchParams(useLocation().search);
    let id = query.get('id');
    let adr = null;
    let deepLink = null;
    if (query.has('adr')) {
        adr = query.get('adr');
        deepLink = `dolya://dolya.net.ua/petition?adr=${adr}&id=${id}`;
    }
    else
    {
        deepLink = `dolya://dolya.net.ua/petition/${id}`;
    }
    let googlePlayLink = 'https://play.google.com/store/apps/details?id=com.campstudio.dolya';
    let appStoreLink = '/iossupport';

    const [isLoad, setIsLoad] = useState(false);


    useEffect(() => {
        if (id) {
            setIsLoad(true);
            window.location.href = deepLink;
        }
        else {
            setIsLoad(false);
        }
    }, [id, isLoad]);

    setTimeout(() => {
        if (iOS) {
            window.location.href = googlePlayLink;
        } else {
            window.location.href = appStoreLink;
        }
    },2000);

    return (
        <div className='flex justify-center items-center flex-auto'>
            {isLoad ? (
                <div className='w-[160px] h-[160px] rounded-full border-[15px] border-[#454545] border-b-[#c3c3c3] animate-spin'></div>
            ) : (
                <div className='flex flex-col items-center gap-4'>

                        <div className="text-5xl">Невідома помилка</div>
                        <Link to='/'><div className="bg-black rounded-xl p-3.5 text-2xl text-white">До конвертора</div></Link>
                </div>
            )}
        </div>
    );
}

export default RedirectToApp;