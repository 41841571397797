import app_store from "../app-store.svg"
import { useLocation, Link } from 'react-router-dom';

function IosSupport()
{
	return (
		<>
			<div className="grid grid-cols-{1} mb-40">
				<img src={app_store}></img>
				<div className="text-4xl text-center">
					Наразі iOS не підтримується. Слідкуйте за оновленнями у нас в <br></br>
					<Link className="rounded-full leading-normal bg-white align-top" to="https://www.instagram.com/dolya.petition?igsh=ZGNjOWZkYTE3MQ==">
						<b className="align-top bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 underline">
							&nbsp;Instagram&nbsp;
						</b>
					</Link>
				</div>
			</div>
		</>
	);
}

export default IosSupport;