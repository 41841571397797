import Home from "./components/Home";
import RedirectToApp from "./components/RedirectToApp";
import MainPage from "./components/MainPage";
import IosSupport from "./components/IosSupport";

const AppRoutes = [
    {
        path: '/converter',
        element: <Home />
    },
    {
        path: '',
        element: <MainPage />
    },
    {
        path: '/petition',
        element: <RedirectToApp />
    },
    {
        path: '/iossupport',
        element: <IosSupport />
    }
];

export default AppRoutes;
